import React from 'react';
import diamond from './diamond.png';
import './forwho.css';

export const ForWhoSection = () => {
  return (
    <div className="bg-section2">
      <div
        className="benefits-list"
        style={{ marginBottom: '40px !important' }}
      >
        <div className="main-heading">
          Czy TEN MASTERCLASS
          <br /> jest dla Ciebie? <br />
        </div>
        <div className="accent-text" style={{ lineHeight: '1' }}>
          Tak, jeśli pragniesz...
        </div>
      </div>

      <div className="regular-text who-container">
        <div className="who-item">
          <img src={diamond} alt="" className="diamond-bullet" />
          <div>
            Poczuć nowy powiew swojej{' '}
            <span className="strong">KOBIECEJ MOCY</span>, dzięki której
            poczujesz się absolutnie nie do zatrzymania
          </div>
        </div>
        <div className="who-item">
          <img src={diamond} alt="" className="diamond-bullet" />
          <div>
            Przyciągać mężczyzn gotowych na pełne zaangażowanie, którzy po
            pierwszej fazie adoracji nie będą się oddalać
          </div>
        </div>
        <div className="who-item">
          <img src={diamond} alt="" className="diamond-bullet" />
          <div>
            Tworzyć relacje, na poziomie jakiego jeszcze nie znałaś, w których
            poczujesz się <span className="strong">BEZPIECZNIE</span> i
            zamienisz ciągłą walkę o <span className="strong">NIEGO</span>, o{' '}
            <span className="strong">RELACJĘ</span> na lekkość i zabawę
          </div>
        </div>
        <div className="who-item">
          <img src={diamond} alt="" className="diamond-bullet" />
          <div>
            Czuć głęboki spokój i wewnętrzną pewność siebie, zamieniając
            poczucie bycia niewystarczającą na{' '}
            <span className="strong">Fuck it I'm worth it!</span>
          </div>
        </div>
        <div className="who-item">
          <img src={diamond} alt="" className="diamond-bullet" />
          <div>
            Stać się kobietą, która <span className="strong">PRZYCIĄGA</span>{' '}
            spojrzenia wszystkich wokół. Patrzysz na nią i choć trudno Ci to
            wyrazić słowami, czujesz, że emanuje od niej{' '}
            <span className="strong">MAGNETYZM</span>, który nie pozwala Ci
            oderwać od niej wzroku
          </div>
        </div>
        <div className="who-item">
          <img src={diamond} alt="" className="diamond-bullet" />
          <div>
            Dołączyć do niewielkiego odsetka{' '}
            <span className="strong">MAGNETYCZNYCH</span> kobiet, które
            przyciągają zaangażowanych mężczyzn, którzy je{' '}
            <span className="strong">ADORUJĄ</span>,{' '}
            <span className="strong">ROZPIESZCZAJĄ</span> i{' '}
            <span className="strong">SPEŁNIAJĄ</span> ich marzenia
          </div>
        </div>
      </div>
    </div>
  );
};
