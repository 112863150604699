import React, { useRef, useEffect, useState } from 'react';
import Hls from 'hls.js';

export const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(true);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = src;
    }
  }, [src]);

  const handlePlayClick = () => {
    videoRef.current
      .play()
      .then(() => {
        setShowPlayButton(false); // Hide play button after video starts
      })
      .catch((error) => {
        console.error('Playback error:', error);
      });
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      {/* Video Element */}
      <video
        ref={videoRef}
        controls
        style={{
          width: '100%',
          maxWidth: '720px',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.5)',
        }}
      />

      {/* Custom Play Button Overlay */}
      {showPlayButton && (
        <button
          onClick={handlePlayClick}
          style={{
            position: 'absolute',
            cursor: 'pointer',
            background: 'rgba(0, 0, 0, 0.7)',
            border: 'none',
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            width="50px"
            height="50px"
          >
            <path d="M8 5v14l11-7z" />
          </svg>
        </button>
      )}
    </div>
  );
};
