import React from 'react';

export const Returns = () => {
  return (
    <div style={{ margin: '20px auto', width: '500px' }}>
      <h1>Polityka zwrotów</h1>
      <p>
        Zgodnie z art. 38 ustawy z dnia 30 maja 2014 r. o prawach konsumenta
        (Dz.U. 2014 poz. 827), prawo odstąpienia od umowy zawartej na odległość
        nie przysługuje w szczególności w przypadku:
      </p>
      <ul>
        <li>
          Świadczenia usług, które zostały w pełni wykonane przed upływem
          14-dniowego terminu na odstąpienie od umowy, za wyraźną zgodą
          Konsumenta oraz po poinformowaniu go o utracie prawa odstąpienia.
        </li>
        <li>
          Dostarczania treści cyfrowych (nienagrywanych na materialnym nośniku),
          jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą
          Konsumenta przed upływem 14-dniowego terminu na odstąpienie od umowy i
          po poinformowaniu go o utracie tego prawa.
        </li>
      </ul>
      <p>
        W związku z powyższym, zakup udziału w Masterclass (usługa świadczona w
        wyznaczonym terminie) oraz otrzymanie nagrania w formie elektronicznej
        (treść cyfrowa) nie podlegają zwrotowi po dokonaniu płatności i
        wyrażeniu zgody na natychmiastowe rozpoczęcie świadczenia.
      </p>
      <p>Prosimy o przemyślane decyzje zakupowe.</p>
    </div>
  );
};
