import React from 'react';
import hero from '../../assets/hero_small.png';
import './Hero.css';

export const Hero = ({
  button,
  tag = 'masterclass',
  title = 'OBUDŹ SWOJĄ|KOBIECĄ MOC',
}) => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        {/* <span className="tag">{tag}</span> */}
        <div className="hero-accent">Masterclass</div>
        <h1>
          {title.split('|').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== title.split('|').length - 1 && <br />}
            </React.Fragment>
          ))}
        </h1>
        {button}
      </div>
      <div className="hero-image">
        <img src={hero} alt="Speaker" />
      </div>
    </div>
  );
};
