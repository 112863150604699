import React from 'react';
import { Countdown } from '../Countdown/Countdown';
import './Count.css';

export const Count = () => {
  // Get the current date and time
  const now = new Date();

  // Calculate the target date for 10 AM Polish time
  const targetDate = new Date();
  targetDate.setUTCHours(9); // 10 AM Polish time is 9 AM UTC
  targetDate.setUTCMinutes(0);
  targetDate.setUTCSeconds(0);
  targetDate.setUTCMilliseconds(0);

  // Adjust the date if the current time is past 10 AM Polish time
  if (now > targetDate) {
    targetDate.setUTCDate(targetDate.getUTCDate() + 1);
  }

  return (
    <>
      <div className="countdown-container">
        <div
          style={{
            width: '500px',
            margin: '20px auto',
            textAlign: 'center',
            scale: '1.5',
          }}
        >
          <h1
            style={{ color: 'white', fontSize: '1.8rem' }}
            className="main-heading"
          >
            Masterclass zaczyna się za:
          </h1>
          <div style={{ marginTop: '20px', opacity: '0.8' }}>
            <Countdown targetDate={targetDate.toISOString()} />
          </div>
        </div>
      </div>
    </>
  );
};
