import React, { useState, useEffect } from 'react';
import { VideoPlayer } from '../components/VideoPlayer';
import '../App.css';

export const Video = () => {
  const [userEmail, setUserEmail] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = async () => {
    if (!userEmail.trim()) {
      return;
    }

    try {
      const response = await fetch('/api/check-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
      });

      const data = await response.json();

      if (data.authorized) {
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        setErrorMessage('');
      } else {
        setErrorMessage('Wprowadzony adres email nie ma wykupionego dostępu.');
      }
    } catch (error) {
      console.error('Error checking email authorization:', error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px',
      }}
    >
      <h1 className="main-heading">Sekrety Kobiecego Magnetyzmu</h1>
      {!isLoggedIn && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <input
            type="email"
            placeholder="Wpisz swój email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              marginBottom: '20px',
              width: '300px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
          {errorMessage && (
            <div style={{ color: 'red', marginBottom: '20px' }}>
              {errorMessage}
            </div>
          )}
          <button
            type="submit"
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#007BFF',
              color: '#fff',
              cursor: 'pointer',
              marginBottom: '20px',
            }}
          >
            Zaloguj się
          </button>
        </form>
      )}
      {isLoggedIn && (
        <VideoPlayer src="https://katejaskolska.pl/hls/output.m3u8" />
      )}
    </div>
  );
};
