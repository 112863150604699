import React from 'react';
import './Testimonials.css';
import Image1 from './1.jpg';
import Image2 from './2.jpg';
import Image3 from './3.jpg';

export const Testimonials = () => {
  return (
    <div className="bg2" style={{ padding: 'calc(1.5em / 2)' }}>
      <div style={{ marginBottom: '40px !important' }}>
        <div className="main-heading">
          OTO co mówią <br />
          kobiety mocy
        </div>
        <div className="testimonials-container">
          <div className="testimonial-item">
            <img src={Image1} alt="Testimonial 1" />
          </div>
          <div className="testimonial-item">
            <img src={Image2} alt="Testimonial 2" />
          </div>
          <div className="testimonial-item">
            <img src={Image3} alt="Testimonial 3" />
          </div>
        </div>
      </div>
    </div>
  );
};
