import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Checkout.css';

const SuccessIcon = () => (
  <div className="success-animation">
    <div className="success-checkmark">
      <div className="check-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-circle"></div>
        <div className="icon-fix"></div>
      </div>
    </div>
  </div>
);

const ErrorIcon = () => (
  <div className="error-animation">
    <div className="error-circle">
      <div className="error-icon">
        <span className="error-line error-line-1"></span>
        <span className="error-line error-line-2"></span>
      </div>
    </div>
  </div>
);

const InfoIcon = () => (
  <div className="info-animation">
    <div className="info-circle">
      <div className="info-icon">
        <span className="info-dot"></span>
        <span className="info-line"></span>
      </div>
    </div>
  </div>
);

const STATUS_CONTENT_MAP = {
  succeeded: {
    text: 'Płatność zakończona sukcesem',
    icon: <SuccessIcon />,
    backgroundColor: '#f8fff8',
    message:
      'Wszystkie informacje zostały wysłane na Twój adres email. Jeśli nie widzisz wiadomości, sprawdź folder spam.',
  },
  processing: {
    text: 'Płatność jest przetwarzana',
    icon: <InfoIcon />,
    backgroundColor: '#f8f9fa',
    message: 'Prosimy o cierpliwość, przetwarzamy Twoją płatność.',
  },
  requires_payment_method: {
    text: 'Płatność nie powiodła się',
    icon: <ErrorIcon />,
    backgroundColor: '#fff8f8',
    message: 'Spróbuj ponownie dokonać płatności.',
    action: 'retry',
  },
  default: {
    text: 'Coś poszło nie tak',
    icon: <ErrorIcon />,
    backgroundColor: '#fff8f8',
    message: 'Spróbuj ponownie dokonać płatności.',
    action: 'retry',
  },
};

export const CompletePage = ({ stripePromise }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [intentId, setIntentId] = useState(null);

  useEffect(() => {
    document.body.style.backgroundColor = '#ffefe5';

    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) return;

      const { paymentIntent } =
        await stripe.retrievePaymentIntent(clientSecret);
      if (!paymentIntent) return;

      console.log('paymentIntent', paymentIntent);
      setStatus(paymentIntent.status);
      setIntentId(paymentIntent.id);

      // Automatically redirect back to checkout after 3 seconds if payment failed
      if (paymentIntent.status === 'requires_payment_method') {
        setTimeout(() => {
          navigate('/masterclass/checkout');
        }, 3000);
      }
    });

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [stripePromise, navigate]);

  if (!status) return null;

  const statusContent =
    STATUS_CONTENT_MAP[status] || STATUS_CONTENT_MAP.default;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div
          className="status-container"
          style={{
            backgroundColor: statusContent.backgroundColor,
            borderRadius: '12px',
            padding: '2rem',
            textAlign: 'center',
          }}
        >
          {statusContent.icon}
          <h2
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '1.5rem',
              color: '#30313d',
              margin: '1.5rem 0',
            }}
          >
            {statusContent.text}
          </h2>
          <p
            style={{
              fontFamily: '-apple-system, system-ui, "Segoe UI", sans-serif',
              fontSize: '14px',
              color: '#666',
              margin: '1rem 0',
              lineHeight: '1.5',
            }}
            dangerouslySetInnerHTML={{ __html: statusContent.message }}
          ></p>
        </div>

        {statusContent.action === 'retry' && (
          <button
            className="submit-button"
            onClick={() => navigate('/masterclass/checkout')}
            style={{ marginTop: '1rem' }}
          >
            Spróbuj ponownie
          </button>
        )}
      </div>
    </div>
  );
};
