import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import './Checkout.css';

export const Checkout = ({ stripePromise }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [dpmCheckerLink, setDpmCheckerLink] = useState('');
  const [plus, setPlus] = useState(false);
  const [promoEnding, setPromoEnding] = useState('');
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const plus = searchParams.get('plus');
    const magnes = searchParams.get('magnes');

    setPlus(plus);

    if (magnes) {
      fetch(`/api/promo-ending/${magnes}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch promo ending');
          }
          return response.json();
        })
        .then((data) => {
          setPromoEnding(data.promo_ending);

          const isPromoValid = () => {
            if (!data.promo_ending) return false;
            const promoEndDate = new Date(data.promo_ending);
            const currentDate = new Date();
            return currentDate < promoEndDate;
          };

          const productId = plus
            ? isPromoValid()
              ? 'masterclassPlusPromo'
              : 'masterclassPlus'
            : isPromoValid()
              ? 'masterclassPromo'
              : 'masterclass';

          setIsPromoValid(isPromoValid());

          // Create PaymentIntent after promo-ending is fetched and checked
          fetch('/api/create-payment-intent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ productId }),
          })
            .then((res) => res.json())
            .then((data) => {
              setClientSecret(data.clientSecret);
              setDpmCheckerLink(data.dpmCheckerLink);
              setPrice(data.price);
            });
        })
        .catch((error) => {
          console.error('Error fetching promo ending:', error);
        });
    } else {
      // Handle case where magnes is not present
      const productId = plus ? 'masterclassPlus' : 'masterclass';

      fetch('/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
          setDpmCheckerLink(data.dpmCheckerLink);
          setPrice(data.price);
        });
    }
  }, [promoEnding]);

  if (!clientSecret || !stripePromise) {
    return (
      <div className="checkout-loader-container">
        <div className="checkout-loader"></div>
      </div>
    );
  }

  return (
    <div className="stripe-checkout">
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance: {
            theme: 'stripe',
            variables: { colorPrimary: '#d67688' },
          },
          loader: 'auto',
          locale: 'pl',
        }}
      >
        <CheckoutForm
          dpmCheckerLink={dpmCheckerLink}
          plus={plus}
          promoEnding={promoEnding}
          isPromoValid={isPromoValid}
          price={price}
        />
      </Elements>
    </div>
  );
};
